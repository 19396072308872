import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <div className="grid-wrapper">
        <div className="col-4">
          <ul className="icons">
            <li>
              <a
                href="https://open.spotify.com/artist/651QgRvgQbnzVI0rj46wM8?si=MVkh3CQZRQqR9VAI0TUsew"
                target="_blank"
                className="icon alt fa-spotify"
              >
                <span className="label">Spotify</span>
              </a>
            </li>
            <li>
              <a
                href="https://sebrene.bandcamp.com/releases"
                className="icon alt fa-bandcamp"
                target="_blank"
              >
                <span className="label">Bandcamp</span>
              </a>
            </li>
            <li>
              <a
                href="https://soundcloud.com/sebrene"
                target="_blank"
                className="icon alt fa-soundcloud"
              >
                <span className="label">Soundcloud</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/sebrene"
                target="_blank"
                className="icon alt fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/sebrenemusic"
                target="_blank"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sebrenemusic/"
                target="_blank"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-8">
          <ul className="copyright">
            <li>
              &copy; Copyright{' '}
              <script type="text/javascript">
                document.write(new Date().getFullYear());
              </script>{' '}
              Sebrene
            </li>
            <li>
              Website Designed & Developed By:{' '}
              <a href="https://designbyamiller.com" target="_blank">
                me
              </a>
            </li>
            <li>
              Cover Art By:{' '}
              <a href="https://www.surudenise.com/" target="_blank">
                surudenise
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
