import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/lyrics">
            Lyrics
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/about">
            About & Contact
          </Link>
        </li>
        <li>
          <a
            href="https://designbyamiller.com/"
            target="_blank"
            className="button fit"
          >
            Design Portfolio
          </a>
        </li>
      </ul>
      <ul className="icons">
        <li>
          <a
            href="https://open.spotify.com/artist/651QgRvgQbnzVI0rj46wM8?si=bpVsgB7uSF6iwhud4lnm4A"
            target="_blank"
            className="icon fa-spotify"
          >
            <span className="label">Spotify</span>
          </a>
        </li>
        <li>
          <a
            href="https://sebrene.bandcamp.com/releases"
            target="_blank"
            className="icon fa-bandcamp"
          >
            <span className="label">Bandcamp</span>
          </a>
        </li>
        <li>
          <a
            href="https://soundcloud.com/sebrene"
            target="_blank"
            className="icon fa-soundcloud"
          >
            <span className="label">Soundcloud</span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/sebrene"
            target="_blank"
            className="icon fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/sebrenemusic"
            target="_blank"
            className="icon fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/sebrenemusic/"
            target="_blank"
            className="icon fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
